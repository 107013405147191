<template>
  <div class="admin">
    <Header />
    <div class="content">
      <div class="container">
        <div class="white_block forlaps">
          <SideBar class="left" />
          <div class="right">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import SideBar from "@/components/4laps/SideBar";
import Footer from "@/components/4laps/Footer";
import Header from "@/components/4laps/Header";

export default {
  name: "Admin",
  components: {
    SideBar,
    Footer,
    Header,
  },
  mounted() {},
};
</script>

<style>
.admin-panel {
  display: flex;
  justify-content: space-between;
}

.side-bar {
  width: 300px;
}

.admin-content {
  width: 100%;
}
</style>
